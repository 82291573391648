import * as React from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
import Logo from "./logo"

const Header = () => {
  const [showMenu, setShowMenu] = React.useState(false)

  const [scrollPosition, setScrollPosition] = React.useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }
  React.useEffect(() => {
    handleScroll()
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const {
    strapi: { tables },
  } = useStaticQuery(graphql`
    query KongsbergHeaderQuery {
      strapi {
        tables: kongsbergTables {
          name
          slug
          title
          id
        }
      }
    }
  `)

  return (
    <header
      className={`py-4 ${
        scrollPosition > 50 ? "bg-white" : "bg-transparent"
      } min-w-full top-0 left-0 z-40 fixed transition-colors`}
    >
      <div className="container mx-auto grid grid-flow-col items-center justify-between h-full">
        <Link to="/">
          <Logo />
        </Link>

        <div
          className="grid grid-flow-row gap-0.5 w-10 py-2 px-2 lg:hidden hover:bg-gray-100 rounded cursor-pointer"
          onClick={() => setShowMenu(true)}
        >
          <span className="h-0.5 bg-gray-900 rounded-sm my-0.5" />
          <span className="h-0.5 bg-gray-900 rounded-sm my-0.5" />
          <span className="h-0.5 bg-gray-900 rounded-sm my-0.5" />
        </div>

        <nav
          className={`lg:hidden top-0 left-0 right-0 bottom-0 p-4 bg-white overflow-scroll ${
            showMenu ? "fixed" : "hidden"
          }`}
          role="navigation"
        >
          <button
            className="appearance-none btn-secondary mb-4"
            onClick={() => setShowMenu(false)}
          >
            Tilbage
          </button>

          <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-4">
            {tables.map(table => (
              <div key={table.slug}>
                <div className="font-semibold mb-2">
                  <span>{table.name}</span>
                  <span className="block w-8 h-1 bg-brand-500" />
                </div>
                <Link
                  to={`/${table.slug}`}
                  className="block py-1 hover:underline"
                >
                  Om skærebordet
                </Link>
                <Link
                  to={`/tools?table=${table.id}`}
                  className="block py-1 hover:underline"
                >
                  Værktøj
                </Link>
                <Link
                  to={`/${table.slug}/specifikationer`}
                  className="block py-1 hover:underline"
                >
                  Tekniske specifikationer
                </Link>
              </div>
            ))}
            <div>
              <div className="font-semibold mb-2">
                <span>Generelt</span>
                <span className="block w-8 h-1 bg-brand-500" />
              </div>
              <Link to="/hvorfor-kongsberg" className="block py-1">
                Hvorfor vælge Kongsberg?
              </Link>
              <Link to="/tools" className="block py-1">
                Værktøj
              </Link>
            </div>

            <div>
              <div className="font-semibold  mb-2">
                <span>Virksomheden</span>
                <span className="block w-8 h-1 bg-brand-500" />
              </div>
              <Link to="/kontakt" className="block py-1">
                Kontakt
              </Link>
              <a href="https://artwork-systems.dk/" className="block py-1">
                Besøg artwork-systems.dk
              </a>
            </div>
          </div>
        </nav>

        <nav className="flex-grow-0 lg:flex hidden" role="navigation">
          <div className="inline-block hover:text-brand-600 nav-bar-item">
            <div className="text-base font-semibold py-2 px-4 rounded cursor-pointer">
              Skærebord
            </div>
            <div className="nav-bar-details -ml-12 absolute bg-white p-2 text-gray-900 hover:text-gray-900 shadow-2xl w-auto max-w-lg rounded-xl">
              {tables.map((table, i) => (
                <Link
                  key={i}
                  to={`/${table.slug}`}
                  className="flex items-center group p-4 hover:bg-brand-100 rounded-lg cursor-pointer"
                >
                  <div className="flex-shrink-0 rounded h-12 w-12 mr-4 font-bold text-lg text-brand-500 bg-brand-200 flex items-center justify-center">
                    {table.name.split(" ").pop()}
                  </div>
                  <div>
                    <div className="text-base font-semibold group-hover:text-brand-600">
                      {table.name}
                    </div>
                    <div className="text-sm text-muted group-hover:text-gray-900">
                      {table.title}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <Link
            to="/hvorfor-kongsberg"
            className="inline-block text-base font-semibold py-2 px-4 hover:text-brand-600 rounded cursor-pointer"
          >
            Hvorfor vælge Kongsberg?
          </Link>
          <Link
            to="/tools"
            className="inline-block text-base font-semibold py-2 px-4 hover:text-brand-600 rounded cursor-pointer"
          >
            Værktøj
          </Link>
          <Link
            to="/kontakt"
            className="inline-block text-base font-semibold py-2 px-4 hover:text-brand-600 rounded cursor-pointer"
          >
            Om os
          </Link>
        </nav>

        <div className="pl-16 hidden lg:block">
          <Link to="/kontakt" className="btn-primary">
            Kontakt os
          </Link>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
