import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import Logo from "./logo"

const Footer = () => {
  return (
    <footer className="bg-brand-100 pt-16 pb-16 text-sm">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 pb-6 gap-4">
          <div className="lg:col-span-2">
            <Logo />
            <p className="my-6">
              Ladegårdsvej 16, 7100 Vejle, DK <br />
              CVR: 20284307
            </p>
            <p>
              Telefon: +45 70 70 20 63
              <br />
              Man-tors: 08.00 - 16.00
              <br />
              Fre: 08.00 - 14.00
            </p>
          </div>
          <div>
            <p className="font-semibold mb-1">
              <span>Kongsberg X</span>
              <span className="block bg-brand-500 h-0.5 w-8" />
            </p>
            <Link to="/kongsberg-x" className="block py-1 hover:underline">
              Om skærebordet
            </Link>
            <Link
              to="/kongsberg-x/tool-heads"
              className="block py-1 hover:underline"
            >
              Tool Heads
            </Link>
            <Link to="/tools?table=1" className="block py-1 hover:underline">
              Værktøj
            </Link>
            <Link
              to="/kongsberg-x/specifikationer"
              className="block py-1 hover:underline"
            >
              Tekniske specifikationer
            </Link>
          </div>
          <div>
            <p className="font-semibold mb-1">
              <span>Kongsberg C</span>
              <span className="block bg-brand-500 h-0.5 w-8" />
            </p>
            <Link to="/kongsberg-c" className="block py-1 hover:underline">
              Om skærebordet
            </Link>
            <Link to="/tools?table=2" className="block py-1 hover:underline">
              Værktøj
            </Link>
            <Link
              to="/kongsberg-c/specifikationer"
              className="block py-1 hover:underline"
            >
              Tekniske specifikationer
            </Link>
          </div>
          <div>
            <p className="font-semibold mb-1">
              <span>Generelt</span>
              <span className="block bg-brand-500 h-0.5 w-8" />
            </p>
            <Link
              to="/hvorfor-kongsberg"
              className="block py-1 hover:underline"
            >
              Hvorfor vælge Kongsberg?
            </Link>
            <Link to="/tools" className="block py-1 hover:underline">
              Søg værktøj
            </Link>
          </div>
          <div>
            <p className="font-semibold mb-1">
              <span>Virksomheden</span>
              <span className="block bg-brand-500 h-0.5 w-8" />
            </p>
            <Link to="/kontakt" className="block py-1 hover:underline">
              Om os
            </Link>
            <a
              href="https://artwork-systems.dk"
              className="block py-1 hover:underline"
            >
              Besøg artwork-systems.dk
            </a>
            <Link to="/privacy" className="block py-1 hover:underline">
              Privalivspolitik
            </Link>
          </div>
        </div>
        <p className="text-center">
          Copyright © {new Date().getFullYear()} Artwork Systems Nordic A/S. All
          rights reserved
        </p>
      </div>
    </footer>
  )
}

export default Footer
